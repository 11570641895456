/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "VITE_APP_WS_ENDPOINT":"https://prod-controlplane.prc.data.app.chaosgears.com",
    "VITE_APP_USE_STREAMING":"true",
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:c3f51f76-b0be-49cb-8384-20b049703a6b",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_8eLrIekxx",
    "aws_user_pools_web_client_id": "4nok72vhqkshaqfheu9a9tqffe",
    "oauth": {
        "domain": 'genai-dev-backend-domain.auth.eu-central-1.amazoncognito.com',
        "googleClientId": "1097914856458-5u5moasvl5cjcqrjaviob92u2rp18ggk.apps.googleusercontent.com",
        //"domain": 'genai-dev-backend-domain.auth.eu-central-1.amazoncognito.com',
        "scope": ['email','profile','openid'], //,'aws.cognito.signin.user.admin'
        "redirectSignIn": 'https://genai.chaosgears.com/,http://localhost:3000/',
        "redirectSignOut": 'https://genai.chaosgears.com/,http://localhost:3000/',
        "responseType": 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": ['Google'],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
   //"aws_appsync_graphqlEndpoint": "https://cui73tbgmnaezjdezumtlabs2y.appsync-api.eu-central-1.amazonaws.com/graphql",
    //"aws_appsync_region": "eu-central-1",
    //"aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
